import { render, staticRenderFns } from "./oldindex.vue?vue&type=template&id=ad235b08&"
import script from "./oldindex.vue?vue&type=script&lang=js&"
export * from "./oldindex.vue?vue&type=script&lang=js&"
import style0 from "./oldindex.vue?vue&type=style&index=0&id=ad235b08&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarWhite: require('/mnt/volume_berlin_01/frontends/mobile-community/components/navbar-white.vue').default,ImageLock: require('/mnt/volume_berlin_01/frontends/mobile-community/components/ImageLock.vue').default,CommunityQuestionList: require('/mnt/volume_berlin_01/frontends/mobile-community/components/CommunityQuestionList.vue').default})
